
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'


import { Core } from '../core/core'
import { SE_CORE_MODULE_BUNDLE, SeCoreModule } from '../core/core.module'

import { SeIconComponent } from './icon.component'
import { IconSizesService } from './icon-sizes.service'

@NgModule({
  declarations: [
    SeIconComponent,
  ],
  providers: [
    IconSizesService,
  ],
  exports: [
    SeIconComponent,
  ],
  imports: [
	CommonModule, 
    SeCoreModule,
  ],
})
export class SeIconModule {}

export const SE_ICON_MODULE_BUNDLE = `${Core.ANGULAR_MODULE}.icon`

