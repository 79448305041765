import { Injectable } from '@angular/core'

import { SassExtract, SassExtractItem, SassExtractDeclaration } from './../core/style-loader/sass-extract'
import { ScssVariable, ScssVariableDictionary } from './../core/style-loader/scss-variable'
import { StyleLoaderService } from './../core/style-loader/style-loader.service'

@Injectable({providedIn: 'root'})
export class IconSizesService {

  public validSizes: string[] = []

  constructor(private styleLoaderService: StyleLoaderService) {
    // noop
  }

  public getDefaultSize(): string {

    if (this.validSizes.length === 0) {
      this.loadSizesFromStyles()
    }

    return this.validSizes[0]

  }

  public isValid(size: string): boolean {

    if (this.validSizes.length === 0) {
      this.loadSizesFromStyles()
    }

    return (this.validSizes.indexOf(size) !== -1)

  }

  public getValidSizes(): string[] {

    if (this.validSizes.length === 0) {
      this.loadSizesFromStyles()
    }

    return this.validSizes

  }

  public getScssVariableValue(): ScssVariable {
    return this.styleLoaderService.getVariable('se-icon-sizes')
  }

  public loadSizesFromStyles(): void {

    const sizeScssVariableValues = this.getScssVariableValue().value

    this.validSizes = []

    for (const sassList of sizeScssVariableValues) {
      this.validSizes.push(sassList.value[0].value.toString())
    }

  }

}
