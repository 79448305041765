
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'


import { Core } from '../core/core'
import { SE_CORE_MODULE_BUNDLE } from '../core/core.module'
import { SeSpinnerModule, SE_SPINNER_MODULE_BUNDLE } from './../spinner/spinner.module'
import { SeIconModule, SE_ICON_MODULE_BUNDLE } from './../icon/icon.module'

import { SeButtonComponent } from './button.component'
import { SeBackButtonComponent } from './back-button/back-button.component'
import { SeDestructiveButtonComponent } from './destructive-button/destructive-button.component'
import { SeIconButtonComponent } from './icon-button/icon-button.component'
import { SeButtonGroupComponent } from './button-group/button-group.component'

@NgModule({
  imports: [
	CommonModule, 
    SeSpinnerModule,
    SeIconModule
  ],
  declarations: [
    SeButtonComponent,
    SeBackButtonComponent,
    SeDestructiveButtonComponent,
    SeIconButtonComponent,
    SeButtonGroupComponent
  ],
  exports: [
    SeButtonComponent,
    SeBackButtonComponent,
    SeDestructiveButtonComponent,
    SeIconButtonComponent,
    SeButtonGroupComponent
  ]
})
export class SeButtonModule {}

export const SE_BUTTON_MODULE_BUNDLE = `${Core.ANGULAR_MODULE}.button`

