import { EventEmitter, Injectable } from '@angular/core'

@Injectable({providedIn: 'root'})
export class EventHandlerService {

  constructor() {
    // noop
  }

  public hasValidEventHandler(eventEmitterHandler: EventEmitter<any>): boolean {

    // for angular 7
    if (eventEmitterHandler && eventEmitterHandler.observers && eventEmitterHandler.observers.length > 0) {
      return true
    }

    // AngularJS fallback
    const handler = <any>eventEmitterHandler
    return (handler && handler.hasOwnProperty('_ngExpressionBindingCb') && handler._ngExpressionBindingCb.length !== 0)

  }

}