import { Component, Input } from '@angular/core'

import { IconSizesService } from './../icon/icon-sizes.service'

@Component({
  selector: 'se-spinner',
  templateUrl: './spinner.component.html',
})
export class SeSpinnerComponent {

  @Input('se-data-size') size: string
  @Input('se-data-white-icon') useWhiteIcon: boolean
  public iconName: string = 'spinner'
  public iconColor: string

  constructor(private iconSizesService: IconSizesService) {
    // noop
  }

  ngOnChanges(): void {
    this.initSize()
    this.initIcon()
    this.initColor()
  }

  public initSize(): void {
    this.size = (this.iconSizesService.isValid(this.size)) ? this.size : this.iconSizesService.getDefaultSize()
  }

  public initIcon(): void {
    this.iconName = (this.shouldUseThinSpinner()) ? 'spinner-thin' : 'spinner'
  }

  public initColor(): void {
    this.iconColor = this.useWhiteIcon ? 'se-icon--white' : 'se-icon--gray-500'
  }

  public shouldUseThinSpinner(): boolean {
    return (parseInt(this.size, 10) > 700)
  }

}
