
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'


import { BreakpointsService } from './breakpoints/breakpoints.service'
import { ContrastCheckerService } from './contrast-checker/contrast-checker.service'
import { Core } from './core'
import { DelayService } from './delay/delay.service'
import { SeElementReadyDirective } from './element-ready.directive'
import { EventHandlerService } from './event-handler/event-handler.service'
import { ImageValidatorService } from './image-validator/image-validator.service'
import { StyleLoaderService } from './style-loader/style-loader.service'

@NgModule({
  imports: [
	CommonModule
  ],
  declarations: [
    SeElementReadyDirective,
  ],
  exports: [
    SeElementReadyDirective,
  ],
  providers: [
    BreakpointsService,
    ContrastCheckerService,
    DelayService,
    EventHandlerService,
    ImageValidatorService,
    StyleLoaderService,
  ],
})
export class SeCoreModule {}

export const SE_CORE_MODULE_BUNDLE = `${Core.ANGULAR_MODULE}.core`

