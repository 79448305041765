import IconImport from './icon-import'
import { IconDictionary, SpriteSymbol } from './icon'

export default class IconTypes {

  public static keyPrefix: string = 'se-ui-icon-'

  public static validTypes: IconDictionary = IconImport.getDictionary()

  public static getIcon(id: string): SpriteSymbol {
    const icon = IconTypes.validTypes[id]
    return (icon) ? icon : undefined
  }

  public static getKeyWithoutPrefix(key: string): string {
    return key.replace(IconTypes.keyPrefix, '')
  }

}
