import { Injectable } from '@angular/core'

import { Breakpoints } from './breakpoints'
import { ResponsiveConstraint } from './responsive-constraint'
import { StyleLoaderService } from '../style-loader/style-loader.service'

@Injectable({providedIn: 'root'})
export class BreakpointsService {

  public breakpoints: Breakpoints = {
    mobile: { minWidth: undefined, maxWidth: undefined },
    tablet: { minWidth: undefined, maxWidth: undefined },
    desktop: { minWidth: undefined, maxWidth: undefined },
    desktopHd: { minWidth: undefined, maxWidth: undefined }
  }

  private isInitialized: boolean = false

  constructor(private styleLoaderService: StyleLoaderService) {
    // noop
  }

  public initializeBreakpoints(): void {

    if (this.isInitialized) {
      return
    }

    for (const key in this.breakpoints) {

      const sassVariableKey = key.replace( /([a-z])([A-Z])/g, '$1-$2' ).toLowerCase()
      const min = this.styleLoaderService.getVariable(`se-breakpoint-${sassVariableKey}-min`)
      const max = this.styleLoaderService.getVariable(`se-breakpoint-${sassVariableKey}-max`)

      this.breakpoints[key] = {
        minWidth: (min) ? min.value : undefined,
        maxWidth: (max) ? max.value : undefined
      }

    }

    this.isInitialized = true

  }

  public getCurrentScreenSizeName(): string {

    this.initializeBreakpoints()

    if (this.isMobile()) {
      return 'mobile'
    }

    if (this.isTablet()) {
      return 'tablet'
    }

    if (this.isDesktop()) {
      return 'desktop'
    }

    if (this.isDesktopHd()) {
      return 'desktopHd'
    }

  }

  public isMobile(): boolean {
    this.initializeBreakpoints()
    return this.windowIsWithinConstraints(this.breakpoints.mobile)
  }

  public isTablet(): boolean {
    this.initializeBreakpoints()
    return this.windowIsWithinConstraints(this.breakpoints.tablet)
  }

  public isDesktop(): boolean {
    this.initializeBreakpoints()
    return this.windowIsWithinConstraints(this.breakpoints.desktop)
  }

  public isDesktopHd(): boolean {
    this.initializeBreakpoints()
    return this.windowIsWithinConstraints(this.breakpoints.desktopHd)
  }

  public windowIsWithinConstraints(constraint: ResponsiveConstraint): boolean {
    const min = (constraint.minWidth === undefined) ? 0 : constraint.minWidth
    const max = (constraint.maxWidth === undefined) ? 100000 : constraint.maxWidth
    const windowWidth = this.getWindowWidth()
    return (windowWidth <= max && windowWidth >= min)
  }

  public getWindowWidth(): number {
    return window.innerWidth
  }

}
