import { Component, Input, SimpleChanges } from '@angular/core'

import { IconSizesService } from './icon-sizes.service'
import { SpriteSymbol } from './icon'

import IconTypes from './icon-types'
import IconAccent from './icon-accent'

@Component({
  selector: 'se-icon',
  templateUrl: './icon.component.html',
})
export class SeIconComponent {

  @Input('name') name: string
  @Input('size') size: string
  @Input('accent') accent: boolean = false

  public icon: SpriteSymbol
  public iconId: string
  public singleColorClass: string = ''
  public iconClass: string = ''

  constructor(private iconSizesService: IconSizesService) {
    // noop
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initIcon()
    this.validateSize()
    this.initClass()
  }

  private initIcon(): void {

    if (this.name && this.name.indexOf(IconTypes.keyPrefix) === -1) {
      this.name = IconTypes.keyPrefix + this.name
    }

    this.icon = IconTypes.getIcon(this.name)

    if (!this.icon) {
      console.warn(`${this.name} is not a valid icon`)
      this.iconId = undefined
      return
    }

    this.iconId = `#${this.icon.id}`

  }

  private validateSize(): void {

    if (this.iconSizesService.isValid(this.size)) {
      return
    }

    this.size = this.iconSizesService.getDefaultSize()

  }

  private initAccentColor(): void {

    if (!this.accent) {
      this.setSingleColorClass(true)
      return
    }

    const canUseAccent = IconAccent.isAvailable(this.icon)

    // turn the single color class on if we cannot use the primary accent color
    this.setSingleColorClass(!canUseAccent)

  }

  private setSingleColorClass(on: boolean): void {
    this.singleColorClass = (on) ? IconAccent.singleColorClassName : ''
  }

  private initClass(): void {
    this.initAccentColor()
    this.iconClass = `se-icon--${this.size}`
    this.iconClass += ` ${this.singleColorClass}`
  }

}
