import { Injectable } from '@angular/core'

@Injectable({providedIn: 'root'})
export class DelayService {

  constructor() {
    // noop
  }

  public delay(duration: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(), duration)
    })
  }

}
