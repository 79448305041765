import { Injectable } from '@angular/core'

import { SassExtract, SassExtractItem, SassExtractDeclaration } from './sass-extract'
import { ScssVariable, ScssVariableDictionary } from './scss-variable'

@Injectable({providedIn: 'root'})
export class StyleLoaderService {

  sassExtract: SassExtract = require('./../../se-ui-components.scss').global
  scssVariableDictionary: ScssVariableDictionary = undefined

  constructor() {
    // noop
  }

  public getVariable(name: string): ScssVariable {

    if (this.scssVariableDictionary && this.scssVariableDictionary[name]) {
      return this.scssVariableDictionary[name]
    }

    return this.getScssVariableBySassExtractKey(`$${name}`)

  }

  public getAllByPrefix(prefix: string): ScssVariable[] {

    if (!this.scssVariableDictionary) {
      this.initData()
    }

    const result: ScssVariable[] = []

    for (const key in this.scssVariableDictionary) {
      if (key.indexOf(prefix) === 0) {
        result.push(this.scssVariableDictionary[key])
      }
    }

    return result

  }

  public loadDictionary(component?: string): ScssVariableDictionary {

    if (!this.scssVariableDictionary) {
      this.initData()
    }

    if (!component) {
      return this.scssVariableDictionary
    }

    const result: ScssVariableDictionary = {}

    for (const key in this.scssVariableDictionary) {
      if (this.variableIsForComponent(this.scssVariableDictionary[key], component)) {
        result[key] = this.scssVariableDictionary[key]
      }
    }

    return result

  }

  public loadDictionaryByFile(file: string): ScssVariableDictionary {

    if (!this.scssVariableDictionary) {
      this.initData()
    }

    if (!file) {
      return this.scssVariableDictionary
    }

    const result: ScssVariableDictionary = {}

    for (const key in this.scssVariableDictionary) {
      if (this.scssVariableDictionary[key].file === file) {
        result[key] = this.scssVariableDictionary[key]
      }
    }

    return result

  }

  private variableIsForComponent(variable: ScssVariable, component: string): boolean {
    return variable.component === component
  }

  private initData(): void {

    this.scssVariableDictionary = {}

    for (const key in this.sassExtract) {
      const scssVariableKey = key.replace('$', '')
      this.scssVariableDictionary[scssVariableKey] = this.getScssVariableBySassExtractKey(key)
    }

  }

  private getScssVariableBySassExtractKey(key: string): ScssVariable {

    if (!this.sassExtract.hasOwnProperty(key)) {
      return undefined
    }

    const item: SassExtractItem = this.sassExtract[key]
    const declaration = item.declarations[0]

    return {
      name: key,
      expression: declaration.expression,
      type: item.type,
      value: item.value,
      unit: item.unit,
      theme: this.getThemeFromDeclaration(declaration),
      component: this.getComponentNameFromDeclaration(declaration),
      file: this.getFileNameFromDeclaration(declaration)
    }

  }

  private getThemeFromDeclaration(declaration: SassExtractDeclaration): string {
    return (declaration.in.indexOf('peacock') !== -1) ? 'peacock' : 'xylo'
  }

  private getComponentNameFromDeclaration(declaration: SassExtractDeclaration): string {
    const componentPath = declaration.in.split('bundle/')[1]
    return componentPath.substring(0, componentPath.indexOf('/'))
  }

  private getFileNameFromDeclaration(declaration: SassExtractDeclaration): string {
    const componentPath = declaration.in.split('bundle/')[1]
    return componentPath.split('_')[1]
  }

}
