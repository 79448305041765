import { SpriteSymbol } from './icon'

export default class IconAccent {

  public static singleColorClassName: string = 'se-icon--single-color'

  public static isAvailable(icon: SpriteSymbol): boolean {

    // Icon is not valid, set the single color class::
    if (!icon) {
      return false
    }

    // Icon is not configured to have accent colors, set the single color class::
    if (icon.content.indexOf('se-icon__accent-path') === -1) {
      return false
    }

    return true

  }

}
