
import { IconDictionary, ImportedIcon } from './icon'

export default class IconImport {

  public static path: string = './assets'

  /**
   * Add new icons to this list.
   * Please list alphabetically.
   */
  public static imports: ImportedIcon[] = [

    require(`${IconImport.path}/affiliation-thin.svg`),
    require(`${IconImport.path}/android-circle-outline.svg`),
    require(`${IconImport.path}/android-circle.svg`),
    require(`${IconImport.path}/android.svg`),
    require(`${IconImport.path}/apple-circle-outline.svg`),
    require(`${IconImport.path}/apple-circle.svg`),
    require(`${IconImport.path}/apple.svg`),
    require(`${IconImport.path}/archive-thin.svg`),
    require(`${IconImport.path}/arrow-down-circle.svg`),
    require(`${IconImport.path}/arrow-left-circle.svg`),
    require(`${IconImport.path}/arrow-right-circle.svg`),
    require(`${IconImport.path}/arrow-up-circle.svg`),
    require(`${IconImport.path}/attach.svg`),
    require(`${IconImport.path}/badge-coaching.svg`),
    require(`${IconImport.path}/badge-development.svg`),
    require(`${IconImport.path}/badge-fun.svg`),
    require(`${IconImport.path}/badge-safety.svg`),
    require(`${IconImport.path}/badge-verified.svg`),
    require(`${IconImport.path}/bank.svg`),
    require(`${IconImport.path}/bell-fill.svg`),
    require(`${IconImport.path}/bell.svg`),
    require(`${IconImport.path}/birth-certificate-thin.svg`),
    require(`${IconImport.path}/calendar.svg`),
    require(`${IconImport.path}/camera-fill.svg`),
    require(`${IconImport.path}/card-autopay.svg`),
    require(`${IconImport.path}/caret-down.svg`),
    require(`${IconImport.path}/caret-left.svg`),
    require(`${IconImport.path}/caret-right.svg`),
    require(`${IconImport.path}/caret-up-down.svg`),
    require(`${IconImport.path}/caret-up.svg`),
    require(`${IconImport.path}/chat-thin.svg`),
    require(`${IconImport.path}/chat.svg`),
    require(`${IconImport.path}/check-circle-outline-thin.svg`),
    require(`${IconImport.path}/check-circle-outline.svg`),
    require(`${IconImport.path}/check-circle.svg`),
    require(`${IconImport.path}/check.svg`),
    require(`${IconImport.path}/chevron-down.svg`),
    require(`${IconImport.path}/chevron-left.svg`),
    require(`${IconImport.path}/chevron-right.svg`),
    require(`${IconImport.path}/chevron-up.svg`),
    require(`${IconImport.path}/clipboard-thin.svg`),
    require(`${IconImport.path}/clapping-hands-thin.svg`),
    require(`${IconImport.path}/comment.svg`),
    require(`${IconImport.path}/copy.svg`),
    require(`${IconImport.path}/discount-thin.svg`),
    require(`${IconImport.path}/download.svg`),
    require(`${IconImport.path}/earth.svg`),
    require(`${IconImport.path}/envelope-cancel-thin.svg`),
    require(`${IconImport.path}/envelope-circle-outline.svg`),
    require(`${IconImport.path}/envelope-circle.svg`),
    require(`${IconImport.path}/envelope-fill.svg`),
    require(`${IconImport.path}/envelope-success-thin.svg`),
    require(`${IconImport.path}/envelope-thin.svg`),
    require(`${IconImport.path}/facebook.svg`),
    require(`${IconImport.path}/facebook-circle-outline.svg`),
    require(`${IconImport.path}/facebook-circle.svg`),
    require(`${IconImport.path}/facebook-thin.svg`),
    require(`${IconImport.path}/flag.svg`),
    require(`${IconImport.path}/flag-fill.svg`),
    require(`${IconImport.path}/gender.svg`),
    require(`${IconImport.path}/google.svg`),
    require(`${IconImport.path}/grip-vertical.svg`),
    require(`${IconImport.path}/grip.svg`),
    require(`${IconImport.path}/handshake-thin.svg`),
    require(`${IconImport.path}/handout-thin.svg`),
    require(`${IconImport.path}/heart-fill.svg`),
    require(`${IconImport.path}/heart.svg`),
    require(`${IconImport.path}/hidden.svg`),
    require(`${IconImport.path}/id-badge-thin.svg`),
    require(`${IconImport.path}/image-multi.svg`),
    require(`${IconImport.path}/info-circle-outline.svg`),
    require(`${IconImport.path}/info-circle.svg`),
    require(`${IconImport.path}/instagram-circle.svg`),
    require(`${IconImport.path}/instagram.svg`),
    require(`${IconImport.path}/invoice-group-thin.svg`),
    require(`${IconImport.path}/invoice-thin.svg`),
    require(`${IconImport.path}/launch.svg`),
    require(`${IconImport.path}/link.svg`),
    require(`${IconImport.path}/linkedin-circle.svg`),
    require(`${IconImport.path}/linkedin.svg`),
    require(`${IconImport.path}/location.svg`),
    require(`${IconImport.path}/lock.svg`),
    require(`${IconImport.path}/menu.svg`),
    require(`${IconImport.path}/merge.svg`),
    require(`${IconImport.path}/minus-circle-outline.svg`),
    require(`${IconImport.path}/minus-circle.svg`),
    require(`${IconImport.path}/minus.svg`),
    require(`${IconImport.path}/more-vertical.svg`),
    require(`${IconImport.path}/more.svg`),
    require(`${IconImport.path}/mute-fill.svg`),
    require(`${IconImport.path}/mute.svg`),
    require(`${IconImport.path}/navigation.svg`),
    require(`${IconImport.path}/pencil-fill.svg`),
    require(`${IconImport.path}/people-fill.svg`),
    require(`${IconImport.path}/people-thin.svg`),
    require(`${IconImport.path}/people.svg`),
    require(`${IconImport.path}/person-add.svg`),
    require(`${IconImport.path}/person-female.svg`),
    require(`${IconImport.path}/person-male.svg`),
    require(`${IconImport.path}/person-remove.svg`),
    require(`${IconImport.path}/person.svg`),
    require(`${IconImport.path}/phone-fill.svg`),
    require(`${IconImport.path}/pin-fill.svg`),
    require(`${IconImport.path}/plus-circle-outline.svg`),
    require(`${IconImport.path}/plus-circle.svg`),
    require(`${IconImport.path}/plus.svg`),
    require(`${IconImport.path}/podium-thin.svg`),
    require(`${IconImport.path}/post-fill.svg`),
    require(`${IconImport.path}/price-tag-thin.svg`),
    require(`${IconImport.path}/print.svg`),
    require(`${IconImport.path}/question-circle-outline.svg`),
    require(`${IconImport.path}/question-circle.svg`),
    require(`${IconImport.path}/question.svg`),
    require(`${IconImport.path}/receipt-thin.svg`),
    require(`${IconImport.path}/refresh.svg`),
    require(`${IconImport.path}/remove-circle-outline.svg`),
    require(`${IconImport.path}/remove-circle.svg`),
    require(`${IconImport.path}/remove.svg`),
    require(`${IconImport.path}/search-thin.svg`),
    require(`${IconImport.path}/search.svg`),
    require(`${IconImport.path}/share-android.svg`),
    require(`${IconImport.path}/share.svg`),
    require(`${IconImport.path}/shield-fill.svg`),
    require(`${IconImport.path}/shirt.svg`),
    require(`${IconImport.path}/signature-thin.svg`),
    require(`${IconImport.path}/snapchat.svg`),
    require(`${IconImport.path}/spinner.svg`),
    require(`${IconImport.path}/spinner-thin.svg`),
    require(`${IconImport.path}/sport-american-football.svg`),
    require(`${IconImport.path}/sport-badminton.svg`),
    require(`${IconImport.path}/sport-baseball.svg`),
    require(`${IconImport.path}/sport-basketball.svg`),
    require(`${IconImport.path}/sport-biathlon.svg`),
    require(`${IconImport.path}/sport-cross-country-skiing.svg`),
    require(`${IconImport.path}/sport-curling.svg`),
    require(`${IconImport.path}/sport-field-hockey.svg`),
    require(`${IconImport.path}/sport-freeski.svg`),
    require(`${IconImport.path}/sport-golf.svg`),
    require(`${IconImport.path}/sport-gymnastics.svg`),
    require(`${IconImport.path}/sport-ice-hockey.svg`),
    require(`${IconImport.path}/sport-inline-skating.svg`),
    require(`${IconImport.path}/sport-lacrosse.svg`),
    require(`${IconImport.path}/sport-rugby.svg`),
    require(`${IconImport.path}/sport-skating.svg`),
    require(`${IconImport.path}/sport-ski-jumping.svg`),
    require(`${IconImport.path}/sport-skiing.svg`),
    require(`${IconImport.path}/sport-snowboarding.svg`),
    require(`${IconImport.path}/sport-soccer.svg`),
    require(`${IconImport.path}/sport-softball.svg`),
    require(`${IconImport.path}/sport-speed-skating.svg`),
    require(`${IconImport.path}/sport-swimming.svg`),
    require(`${IconImport.path}/sport-tennis.svg`),
    require(`${IconImport.path}/sport-volleyball.svg`),
    require(`${IconImport.path}/sport-wrestling.svg`),
    require(`${IconImport.path}/store-thin.svg`),
    require(`${IconImport.path}/store.svg`),
    require(`${IconImport.path}/third-north.svg`),
    require(`${IconImport.path}/trash.svg`),
    require(`${IconImport.path}/twitter-circle-outline.svg`),
    require(`${IconImport.path}/twitter-circle.svg`),
    require(`${IconImport.path}/twitter.svg`),
    require(`${IconImport.path}/undo.svg`),
    require(`${IconImport.path}/upload.svg`),
    require(`${IconImport.path}/wallet.svg`),
    require(`${IconImport.path}/warning.svg`),
    require(`${IconImport.path}/website.svg`),
    require(`${IconImport.path}/whistle-thin.svg`),
    require(`${IconImport.path}/youtube-circle-outline.svg`),
    require(`${IconImport.path}/youtube-circle.svg`),
    require(`${IconImport.path}/youtube.svg`)
  ]

  public static excludeFromDocs: string[] = [
    'spinner',
    'spinner-thin'
  ]

  public static aliases: any = {
    'flag': [
      'sport-other',
      'sport-archery',
      'sport-ball-hockey',
      'sport-bandy',
      'sport-bobsled',
      'sport-bowling',
      'sport-cheerleading',
      'sport-climbing',
      'sport-cricket',
      'sport-cross-country',
      'sport-cycling',
      'sport-dance',
      'sport-diving',
      'sport-fencing',
      'sport-roller-hockey',
      'sport-sled-hockey',
      'sport-judo',
      'sport-karate',
      'sport-taekwondo',
      'sport-para-biathlon',
      'sport-rifle-shooting',
      'sport-trap-shooting',
      'sport-short-track-skating',
      'sport-snowshoe',
      'sport-para-alpine-skiing',
      'sport-para-cross-country-skiing',
      'sport-para-snowboarding',
      'sport-running',
      'sport-track-and-field',
      'sport-water-polo',
      'sport-paintball',
      'sport-luge',
      'sport-pentathlon',
      'sport-racquetball',
      'sport-rowing',
      'sport-sailing',
      'sport-skeleton',
      'sport-squash'
    ],
    'sport-curling': ['sport-wheelchair-curling'],
    'sport-ski-jumping': ['sport-nordic-combined'],
    'sport-skiing': ['sport-alpine-skiing'],
    'sport-freeski': ['sport-freestyle-skiing'],
    'sport-skating': ['sport-ice-dance', 'sport-ice-skating', 'sport-figure-skating'],
    'sport-soccer': ['sport-futsal']
  }

  public static getDictionary(): IconDictionary {

    const allIcons = IconImport.imports
    const dictionary: IconDictionary = {}

    for (const importedIcon of allIcons) {

      const key = importedIcon.default.id
      const keyWithoutPrefix = key.replace('se-ui-icon-', '')
      const aliases = IconImport.aliases[keyWithoutPrefix]

      dictionary[key] = importedIcon.default
      dictionary[key].aliases = aliases

      // add excludeFromDocs if relevant
      if (IconImport.excludeFromDocs.indexOf(keyWithoutPrefix) !== -1) {
        dictionary[key].excludeFromDocs = true
      }

      // create an item for each alias
      if (aliases) {
        for (const aliasKey of aliases) {
          dictionary[`se-ui-icon-${aliasKey}`] = {
            id: importedIcon.default.id,
            viewBox: importedIcon.default.viewBox,
            content: importedIcon.default.content,
            aliasFor: key
          }
        }
      }

    }

    return dictionary

  }

}
