import { Injectable } from '@angular/core'

@Injectable({providedIn: 'root'})
export class ImageValidatorService {

  constructor() {
    // noop
  }

  public isValidImage(url: string): Promise<boolean> {

    if (!url) {
      return Promise.resolve(false)
    }

    return new Promise((resolve) => {
      const image = new Image()
      image.onerror = () => resolve(false)
      image.onload = () => resolve(true)
      image.src = url
    })

  }

}
