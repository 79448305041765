
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'


import { Core } from '../core/core'
import { SE_ICON_MODULE_BUNDLE, SeIconModule } from '../icon/icon.module'
import { SeSpinnerComponent } from './spinner.component'

@NgModule({
  declarations: [
    SeSpinnerComponent
  ],
  imports: [
	CommonModule, 
    SeIconModule
  ],
  exports: [
    SeSpinnerComponent
  ]
})
export class SeSpinnerModule { }

export const SE_SPINNER_MODULE_BUNDLE = `${Core.ANGULAR_MODULE}.spinner`

