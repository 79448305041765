import { Injectable } from '@angular/core'
import { RGB } from './rgb'

@Injectable({providedIn: 'root'})
export class ContrastCheckerService {

  constructor() {
    // noop
  }

  public passes(colorOne: string, colorTwo: string): boolean {

    const colorOneRgb = this.hexToRgb(colorOne)
    const colorTwoRgb = this.hexToRgb(colorTwo)

    if (!colorOneRgb || !colorTwoRgb) {
      return false
    }

    const contrast = this.getContrast(colorOneRgb, colorTwoRgb)
    return (contrast > 3)

  }

  public hexToRgb(hex: string): RGB {

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

    if (result) {
      return { r: parseInt(result[1], 16), g: parseInt(result[2], 16), b: parseInt(result[3], 16) }
    }

    return undefined

  }

  public getLuminance(rgb: RGB): number {

    const a = [rgb.r, rgb.g, rgb.b].map((v: number) => {
      v /= 255
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
    })

    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722

  }

  public getContrast(colorOne: RGB, colorTwo: RGB): number {

    let result = (this.getLuminance(colorOne) + 0.05) / (this.getLuminance(colorTwo) + 0.05)

    if (result < 1) {
      result = 1 / result
    }

    return result

  }

}
