<button (click)="buttonClick($event)" [ngClass]="buttonClass" [disabled]="isDisabled">
  <span class="se-button__content" (click)="contentClick($event)">
    <se-icon *ngIf="iconName" name="{{ iconName }}" size="{{ iconSize }}"></se-icon>
    {{ value }}
  </span>
  <se-spinner
    *ngIf="isLoading"
    (click)="contentClick($event)"
    se-data-white-icon="true"
    se-data-size="500">
  </se-spinner>
</button>
