import { Component, HostBinding, Input, SimpleChanges } from '@angular/core'
import { SeButtonEmphasis } from './button-emphasis'

const BASE_CLASS: string = 'se-button'

@Component({
  selector: 'se-button',
  templateUrl: './button.component.html',
})
export class SeButtonComponent {

  @Input('se-data-emphasis') emphasis: SeButtonEmphasis
  @Input('se-data-icon') iconName: string
  @Input('se-data-icon-size') iconSize: string = '500'
  @Input('se-data-is-disabled') seDataIsDisabled: boolean = false
  @Input('se-data-is-loading') isLoading: boolean = false
  @Input('se-data-value') value: string

  public isDisabled: boolean = false
  public buttonClass: string = BASE_CLASS

  @HostBinding('class.se-low-button') isLow: boolean = false
  @HostBinding('class.se-medium-button') isMedium: boolean = false
  @HostBinding('class.se-high-button') isHigh: boolean = false
  @HostBinding('class.se-button--full-width') @Input('se-data-is-full-width') isFullWidth: boolean = false

  constructor() {
    // noop
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.validate()
    this.initClass()
    this.isDisabled = (this.isLoading) ? true : this.seDataIsDisabled
  }

  public buttonClick($event: any): void {
    const element = $event.currentTarget || $event.srcElement
    if (element) {
      element.blur()
    }
  }

  public contentClick($event: any): void {
    if (this.isDisabled) {
      $event.stopPropagation()
    }
  }

  private validate(): void {
    this.validateEmphasis()
  }

  private validateEmphasis(): void {
    if (!(Object as any).values(SeButtonEmphasis).includes(this.emphasis)) {
      console.warn(`Invalid button emphasis type: ${this.emphasis}`)
    }
  }

  private initClass(): void {

    let buttonClass = `${BASE_CLASS}--${this.emphasis}`
    buttonClass += (this.isLoading) ? ` ${BASE_CLASS}--is-loading` : ''
    buttonClass += (this.isFullWidth) ? ` ${BASE_CLASS}--full-width` : ''
    this.buttonClass = buttonClass

    this.isLow = this.emphasis === SeButtonEmphasis.Low
    this.isMedium = this.emphasis === SeButtonEmphasis.Medium
    this.isHigh = this.emphasis === SeButtonEmphasis.High

  }

}